export default {
  methods: {
    rvrv (list) {
      if (!list) {
        return []
      }
      const arr = [...list]
      arr.reverse()
      return arr
    },
    nameOnlyList (list) {
      const vm = this
      return (list || []).map(function(n) {
        return vm.nameOnly(n)
      })
    },
    emailOnlyList (list) {
      const vm = this
      return (list || []).map(function (n) {
        return vm.emailOnly(n)
      }).filter(function(n) {
        return n && n !== ''
      })
    },
    nameOnly (n) {
      if (!n || typeof n != 'string') {
        n = ''
      }
      const ans = (n || '').replace(/\(.*\)/, '') || ''
      return ans
    },
    emailOnly (n) {
      if (typeof n != 'string') {
        n = ''
      }
      if (!n) {
        n = ''
      }
      if (!n.match(/.+\(.*\)/)) {
        return ''
      }
      const ans = n.replace(/.+\(/, '').replace(/\).*/, '')
      return ans
    },
    countDeltaHr (r) {
      const t = (new Date()).getTime()
      const y = (new Date()).getFullYear()
      const m = (new Date()).getMonth()
      const d = (new Date()).getDate()
      let date = r.date

      for (let i = 0; i < (r.dates || []).length; i++) {
        if (parseInt(r.dates[i][0]) <=t
            && t <= parseInt(r.dates[i][1])) {
          date = r.dates[i][0]

          const delta = date - t
          if (delta < 0) {
            return 0
          }
          return Math.floor(delta / 1000 / 60 / 60)
        }
        if (r.dates[i][0] > t
          && r.dates[i][1] > t
          ) {
          date = r.dates[i][0]

          const delta = date - t
          if (delta < 0) {
            return 0
          }
          return Math.floor(delta / 1000 / 60 / 60)
        }        
      }

      const delta = date - t
      if (delta < 0) {
        return 0
      }
      return Math.floor(delta / 1000 / 60 / 60)
    },
    countDelta (j) {
      const d = new Date().getTime()
      const delta = j - d
      if (delta < 0) {
        return 0
      }
      return Math.floor(delta / 1000 / 60 / 60 / 24)
    },
    ee (s) {
      if ((''+s).length == 1) {
        s = '0' + s
      }
      return s
    },
    getGoodByName (name, goodteachers) {
      let ans
      const ts = this.obj_to_list(goodteachers)
      for (let j = 0; j < ts.length; j++) {
        if (ts[j].name === name) {
          ans = ts[j]
        }
      }
      // console.log(ans)
      return ans
    },
    par (d) {
      // console.log(d)
      d = parseInt(d)
      const date = new Date(d)
      // console.log(date)
      const ans = date.getFullYear() + '/' + (date.getMonth() + 1) + '/' + date.getDate()
      return ans
    },
    parseQA (m, n) {
      return m.replace('__NAME__', n)
    },
    obj_to_list (obj: any) {
      if (!obj) {
        return []
      }
      // console.log(obj)
      const ks = Object.keys(obj)
      const list = ks.map(function (i) {
        // console.log(obj[i])
        return obj[i]
      })
      return list
    },
    list_to_obj (list: any) {
      if (!list) {
        return {}
      }
      const obj = {}
      for (let i = 0; i < list.length; i++) {
        obj[list[i].id] = list[i]
      }
      return obj
    },
    ser (obj:any, k:string, rid:any) {
      const ans: any = []
      const keys = Object.keys(obj)
      for (let i = 0; i < keys.length; i++) {
        if ((JSON.stringify(obj[keys[i]]).indexOf(k) > -1 || !k) &&
          (!rid || obj[keys[i]].rooms.map(function (r) {
            return r.id
          }).indexOf(rid) > -1)) {
          ans.push(obj[keys[i]])
          // console.log(obj[keys[i]])
        }
      }
      return ans
    },
    ser1 (obj:any, k:string, rid:any) {
      const ans: any = []
      const keys = Object.keys(obj)
      for (let i = 0; i < keys.length; i++) {
        if ((JSON.stringify(obj[keys[i]]).indexOf(k) > -1 || !k) &&
          ((obj[keys[i]].rooms || []).filter(function (r) {
            return r.status == '已確認' || r.status == '已完成' || r.status == '教師'
          }).map(function (r) {
            return r.id
          }).indexOf(rid) > -1)) {
          ans.push(obj[keys[i]])
          // console.log(obj[keys[i]])
        }
      }
      //console.log(ans)
      return ans
    },
    countDeltaSec (t, d) {
      const delta = d - t
      if (delta / 1000 <= 20) {
        return '倒數' + Math.floor(20 - delta / 1000) + '秒後即可連線'
      } else {
        return '連線中，等候對方加入'
      }
    },
    countDeltaSec1 (t, d) {
      const delta = d - t
      if (delta / 1000 <= 20) {
        return '倒數' + Math.floor(20 - delta / 1000) + '秒後即可連線'
      } else {
        return '老師現在位於其他同學的空間，有問題請寫上留言版'
      }
    },
    countDeltaSec2 (t, d) {
      const delta = d - t
      if (delta / 1000 <= 20) {
        return '倒數' + Math.floor(20 - delta / 1000) + '秒後即可連線'
      } else {
        return '老師尚未上線，請稍待'
      }
    },
    showTime (date: any) {
      if (!date) {
        return null
      }
      date = parseInt(date)
      const d = new Date(date)
      return d.getFullYear() + '/' + (d.getMonth() + 1) + '/' + d.getDate() + '\u00A0\u00A0\u00A0\u00A0\n'
       + this.showTimeOnly(date)
    },
    showTimeOnly (date: any) {
      if (!date) {
        return null
      }
      date = parseInt(date)
      const d = new Date(date)
      const h = d.getHours()
      let s = ''
      if (h > 12) {
        s = '下午 ' + (h - 12)
      } else if (h === 12) {
        s = '下午 ' + h
      } else {
        s = '上午 ' + h
      }
      function ee (s) {
        if ((''+s).length == 1) {
          s = '0' + s
        }
        return s
      }
      return s + ':' + this.ee(d.getMinutes())
    },
    countS (r) {
      const t = (new Date()).getTime()
      const y = (new Date()).getFullYear()
      const m = (new Date()).getMonth()
      const d = (new Date()).getDate()

      let date = r.date

      // console.log(new Date(date))
      // console.log(r.dates)

      for (let i = 0; i < (r.dates || []).length; i++) {
        // console.log(r.dates[i][0])
        // console.log(t)
        // console.log(r.dates[i][1])

        if (parseInt(r.dates[i][0]) <=t
            && t <= parseInt(r.dates[i][1])) {

          date = r.dates[i][0]

          // console.log(new Date(date))

          const diff = date - t

          // console.log(diff)

          if (diff < 0) {
            return 0
          }
          const days = Math.floor((diff) / 1000 / 60 / 60 / 24)
          const hours = Math.floor((diff - (days * 24 * 60 * 60 * 1000)) / 1000 / 60 / 60)
          const minutes = Math.floor((diff - (days * 24 * 60 * 60 * 1000) - (hours * 60 * 60 * 1000)) / 1000 / 60)

          let ans = ''
          if (days > 0) {
            ans += days + '天'
          }
          if (hours > 0) {
            ans += hours + '小時'
          }
          if (minutes > 0) {
            ans += minutes + '分鐘'
          }
          return ans
        }
        if (r.dates[i][0] > t
          && r.dates[i][1] > t
          ) {
          date = r.dates[i][0]

          const diff = date - t

          // console.log(diff)

          if (diff < 0) {
            return 0
          }
          const days = Math.floor((diff) / 1000 / 60 / 60 / 24)
          const hours = Math.floor((diff - (days * 24 * 60 * 60 * 1000)) / 1000 / 60 / 60)
          const minutes = Math.floor((diff - (days * 24 * 60 * 60 * 1000) - (hours * 60 * 60 * 1000)) / 1000 / 60)

          let ans = ''
          if (days > 0) {
            ans += days + '天'
          }
          if (hours > 0) {
            ans += hours + '小時'
          }
          if (minutes > 0) {
            ans += minutes + '分鐘'
          }
          return ans
        }        
      }

      // console.log(date)

      const diff = date - t

      // console.log(diff)

      if (diff < 0) {
        return 0
      }
      const days = Math.floor((diff) / 1000 / 60 / 60 / 24)
      const hours = Math.floor((diff - (days * 24 * 60 * 60 * 1000)) / 1000 / 60 / 60)
      const minutes = Math.floor((diff - (days * 24 * 60 * 60 * 1000) - (hours * 60 * 60 * 1000)) / 1000 / 60)

      let ans = ''
      if (days > 0) {
        ans += days + '天'
      }
      if (hours > 0) {
        ans += hours + '小時'
      }
      if (minutes > 0) {
        ans += minutes + '分鐘'
      }
      return ans
    },
    isToday (date: any) {
      const d = new Date(date)
      const t = new Date()
      return (
        d.getFullYear() === t.getFullYear()  &&
        d.getMonth() === t.getMonth() &&
        d.getDate() === t.getDate()
      )
    }
  }
}