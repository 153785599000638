import 'babel-polyfill'
import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import Vue3TouchEvents from 'vue3-touch-events'
import Datepicker from '@vuepic/vue-datepicker'
import VueExcelXlsx from 'vue-excel-xlsx'
import VueGtag from 'vue-gtag'
import './db'

import '@vuepic/vue-datepicker/dist/main.css'
import 'vue3-carousel/dist/carousel.css'

import { createHead } from 'unhead'

// Create a global head instance
createHead()

createApp(App).component('Datepicker', Datepicker)
	.use(router)
	.use(VueGtag, {
      config: { id: 'G-ZPW8EXB2NZ' }
	}, router)
	.use(VueExcelXlsx)
	.use(Vue3TouchEvents)
	.mount('#app')