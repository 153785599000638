import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database' 
import { getAuth } from 'firebase/auth'

// Get a Firestore instance
const fb = initializeApp({
  apiKey: "AIzaSyBJXQmyiWCjYtv5HPIK3AgO8YDlzJiEyyw",
  authDomain: "et-tech-edu.firebaseapp.com",
  databaseURL: "https://et-tech-edu-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "et-tech-edu",
  storageBucket: "et-tech-edu.appspot.com",
  messagingSenderId: "214776474119",
  appId: "1:214776474119:web:2401b34b03b583f68e98a2",
  measurementId: "G-ZPW8EXB2NZ"
});


export const db = getDatabase(fb)
export const auth = getAuth(fb)
